/* src/styles/Dashboard.css */
.dashboard {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.today-total, .projections, .debts-income {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.projection-item {
  margin-bottom: 10px;
}

.debts-income {
  display: flex;
  justify-content: space-between;
}

.debts, .income {
  width: 48%;
}
